<template>
  <div class="mod-config">
    <el-table
        :data="dataList"
        border
        v-loading="dataListLoading"
        style="width: 100%;">
      <el-table-column
          type="index"
          header-align="center"
          align="center" width="50"
          label="序号">
      </el-table-column>
      <el-table-column
          prop="uname"
          header-align="center"
          align="center"
          label="填报人姓名">
      </el-table-column>
      <el-table-column
          prop="servers"
          header-align="center"
          min-width="120"
          align="center"
          label="服务人">
        <template slot-scope="scope">
          <div v-if="scope.row.servers" type="text"
               size="small">
            <el-tag style="margin: 2px;" size="mini" v-for="(item,index) in scope.row.servers.split(/[;；]/g)"
                    :key="index+'-'+item">{{item}}</el-tag>
          </div>
          <span v-else>无填写</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="mainOrgName"
          header-align="center"
          width="120"
          align="center"
          label="单位">
        <template slot-scope="scope">
          <el-popover
              placement="top-start"
              title="单位"
              width="200"
              trigger="hover"
              :content="scope.row.mainOrgName">
            <span slot="reference">{{ $utils.getLimitText(scope.row.mainOrgName) }}</span>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
          prop="secondOrgName"
          header-align="center"
          align="center"
          width="120"
          label="种质(圃)">
        <template slot-scope="scope">
          <el-popover
              placement="top-start"
              title="种质(圃)"
              width="200"
              trigger="hover"
              :content="scope.row.secondOrgName">
            <span slot="reference">{{ $utils.getLimitText(scope.row.secondOrgName) }}</span>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
          prop="wechatName"
          header-align="center"
          align="center"
          label="填报人微信名">
      </el-table-column>
      <el-table-column
          prop="serveObjectName"
          header-align="center"
          align="center"
          label="服务对象名称">
      </el-table-column>
      <el-table-column
          prop="sid"
          header-align="center"
          align="center"
          label="服务类型">
        <template slot-scope="scope">
          <span>{{ scope.row.sname?scope.row.sname:getNameById(scope.row.sid, serveTypeList) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="oid"
          header-align="center"
          align="center"
          label="服务对象类型">
        <template slot-scope="scope">
          <span>{{ scope.row.oname?scope.row.oname:getNameById(scope.row.oid, objTypeList) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="serveNum"
          header-align="center"
          align="center"
          label="服务人次">
      </el-table-column>
      <el-table-column
          prop="serveDate"
          header-align="center"
          align="center"
          label="服务日期">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      dataForm: {
        key: ''
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    }
  },
  computed: {
    ...mapGetters({
      serveTypeList: 'serveTypeList',
      objTypeList: 'objTypeList'
    })
  },
  // created() {// TODO sname和oname信息在后台就已经获取好了，所以这里不需要了，后台接口成熟的话到时候再删除前端相关的逻辑
  //   this.getServeAndObjTypeList();
  // },
  mounted() {
    setTimeout(() => {
      this.getDataList()
    }, 300)
  },
  methods: {
    getNameById(id, list) {
      if (!list) return null;
      let str = '';
      for (let i = 0, len = list.length; i < len; i++) {
        const l = list[i];
        if (l.id === id) {
          str = l.name;
          break;
        }
      }
      return str;
    },
    getServeAndObjTypeList() {
      this.$store.dispatch('wechat/getObjTypeList')
      this.$store.dispatch('wechat/getServeTypeList')
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/wechat/servecontent/list'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'key': this.dataForm.key,
          'order': 'DESC',
          'sidx': 'serve_date'
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.dataList = data.page.list
          this.totalPage = data.page.totalCount
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
  }
}
</script>
